<template>
  <div id="fellow-travelers">
    <p class="title">{{$t('settings.fellowtravelers.title')}}</p>
    <p class="description">{{$t('settings.fellowtravelers.description')}}</p>
    <p class="note">{{$t('settings.fellowtravelers.yourFellowTravelers')}}</p>
    <div class="guests">
      <div class="guest" v-for="(guest, key) in guests" :key="key">
        <md-icon class="person">person</md-icon>
        <p class="name">{{guest.firstName}} {{guest.lastName}}</p>
        <div class="clickable" @click="$router.push(`/settings/fellowtravelers/edit/${guest.guestId}`)"></div>
        <md-icon class="delete" @click.native="deleteGuest(guest)">delete</md-icon>
      </div>
    </div>
    <div class="add" @click="$router.push('/settings/fellowtravelers/add')">
      <p class="label">{{$t('settings.fellowtravelers.addFellowTraveler')}}</p>
      <md-icon class="next">navigate_next</md-icon>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      guests: []
    }
  },
  mounted() {
    this.$api.get(`user/guests`)
    .then(res => {
      this.guests = res.data.data
    })
  },
  methods: {
    deleteGuest(guest) {
      this.$api.delete(`user/guests/${guest.guestId}`)
      .then(() => {
        this.$api.get(`user/guests`)
        .then(res => {
          this.guests = res.data.data
        })
      })
    }
  },
}
</script>

<style lang="scss">
  #fellow-travelers {
    margin: 0 auto;
    max-width: 500px;
    font-family: Roboto;

    .title {
      color: #47525E;
      font-size: 32px;
      margin-bottom: 30px;
      text-align: center;
      line-height: 30px;
    }
    .description {
      color: #7a7a7a;
      font-size: 15px;
      padding: 0 15px 0 15px;
    }
    .note {
      color: black;
      text-transform: uppercase;
      background-color: #e6edf5;
      padding: 5px 0 5px 15px;
      cursor: default;
      margin-bottom: 0;
    }
    
    .guests {
      .guest {
        width: 100%;
        height: 60px;
        border-bottom: 1px #ccc solid;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: .2s all;
        &:hover {
          background-color: rgba(0, 0, 0, .02);
        }

        >* {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .clickable {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transform: none;
        }

        .person {
          left: 10px;
        }
        .name {
          left: 35px;
          font-size: 16px;
        }
        .delete {
          right: 10px;
          transition: .2s all;

          &:hover {
            color: rgb(201, 93, 93);
          }
        }
      }
    }
    .add {
      width: 100%;
      height: 60px;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      transition: .2s all;
      &:hover {
        background-color: rgba(0, 0, 0, .02);
      }

      >* {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .label {
        left: 10px;
        font-size: 16px;
      }
      .next {
        right: 10px;
      }
    }
  }
</style>